// Vars
@primary: #001871;
@primary-dark:#232229;
@ios-purple:#39449C;
@ios-purple-light:#E2E3F0;
@secondary: #00A3E0;
@tertiary: #00C19F;
@tertiary-light:#A8EADE;
@tertiary-dark:#006B57;
@border:#EAEBEF;
@border-medium:#DEDFE7;
@border-light:lighten(@border, 3%);
@color__grey-dark:#232229;
@color__grey-medium-light:#65666B;
@color__grey-medium:rgba(0,0,0,.7);
@color__grey-medium-dark:#39393F;
@color__grey-light:#727380;
@color__grey-lighter:#A7A9B2;
@color__error:#FF331F;
@color__error-dark:#B01B0C;
@color__error-light:#FFC6C1;
@color__error-lighter:#FFF7F7;
@color__warning-dark:#B35C05;
@color__warning-light:#FEEBD7;
@thead:#F3F4F9;

@regular:400;
@medium:500;
@semi-bold:600;
@bold:700;

@font__small: .875rem;
@font__medium: 1.125rem;
@font__medium-large: 1.25rem;
@font__large: 1.5rem;
@font__xlarge: 2rem;
@font__xxlarge: 2.5rem;

@font-family: 'Inter', sans-serif;

@field-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);

.breakpoint-mobile(@rules) {
  @media screen and (max-width: 480px) { @rules(); }
}

.breakpoint-tablet(@rules) {
  @media screen and (max-width: 768px) { @rules(); }
}

.breakpoint-md-tablet(@rules){
  @media screen and (max-width: 1030px) { @rules(); }
}

.breakpoint-lg-tablet(@rules){
  @media screen and (max-width: 1200px) { @rules(); }
}

.breakpoint-extra-lg-tablet(@rules){
  @media screen and (max-width: 1400px) { @rules(); }
}

.breakpoint-desktop(@rules){
  @media screen and (min-width: 1200px) { @rules(); }
}

.breakpoint-not-mobile(@rules){
  @media screen and (min-width: 481px) { @rules(); }
}