@import "_variables";

.breakpoint-lg-tablet(@rules){
  @media screen and (max-width: 1200px) { @rules(); }
}

.aro-login-wrapper {
  display:flex;
  height:100%;

  .left-pane, .right-pane {
    flex:0 1 50%;
    align-items: stretch;
    padding:20px;
  }

  .left-pane {
    display:flex;
    align-items:center;

    .breakpoint-lg-tablet({
      flex:1 1 auto;
    });

    .footer-group {
      position:absolute;
      padding: 20px 20px;
      width:100%;
      bottom:0px;
      font-size:14px;
      left:0px;
      color:@color__grey-light;
    }

    & > .content {
      display:flex;
      flex-direction: column;
      max-width:335px;
      width:100%;
      margin:0 auto;
      padding-bottom:130px; //used to offset the box off center

      .header-group {
        display:flex;
        align-items:center;
        font-family:'Montserrat','Inter',sans-serif;
        margin-bottom:130px;

        .breakpoint-lg-tablet({
          margin-bottom:70px;
        });
  
        & > img {
          height:37px;
          width:37px;
          margin-right:20px;
        }
  
        & > .heading {
          color: @primary;
          font-size:18px;
          font-weight:@bold;
        }
      }

      .form-group {
        h1 {
          font-style: normal;
          font-weight: 600;
          font-size: 48px;
          line-height: 58px;
          color:@primary-dark;

          .breakpoint-lg-tablet({
            font-size:34px;
          });
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.4em;
          color:@color__grey-medium-light;
        }

        .form-content-group {
          // display:flex;
          overflow:hidden;
          margin-top:32px;
          // flex-wrap: nowrap;
          width:100%;
          position:relative;
          height:150px;

          .auth-alternative {
            margin-top:14px;

            .link {
              color:@primary;
              cursor:pointer;
              transition: color 0.2s ease;
            
              &:hover {
                color:lighten(@primary, 20%);
              }
            }
          }

          &.microsoft-active {
            .microsoft-group {
              left:0px;
            }
          }

          &.magiclink-active {
            .magiclink-group {
              left:0px;
            }
          }

          &.success-active {
            .success-group {
              left:0px;
            }

            .magiclink-group {
              left:-110%;
            }
          }

          .magiclink-group, .success-group, .microsoft-group {
            position:absolute;
            top:0px;
            left:110%;
            width: 100%;
            transition: left 0.2s ease;
          }

          .microsoft-group {
            left:-110%;
          }

          .success-group {
            border-radius:8px;
            background-color: #CFF4ED;
            color:@tertiary-dark;
            padding:20px;

            .heading {
              font-weight:@semi-bold;
              font-size:16px;
            }

            .description {
              margin-top:8px;
              font-size:14px;

              span {
                font-weight: @semi-bold;
              }
            }
          }
        }

        .powered-by {
          margin-top:17px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color:@color__grey-medium;
        }
      }
    }
  }

  .right-pane {
    //background: [background-image] [background-position] / [background-size] [background-repeat] [background-attachment] [background-origin] [background-clip] [background-color];
    background: linear-gradient(0deg, rgba(0, 24, 113, 0.71), rgba(0, 24, 113, 0.71)), url('../img/aro-holding-phone.jpg') center right / cover no-repeat;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
    padding-left:40px;
    padding-right:40px;

    .breakpoint-lg-tablet({
      display:none;
    });

    & > .content {
      color:#fff;
      max-width:646px;

      img {
        width:143px;
        margin-bottom:35px;
      }

      h1 {
        font-weight: 400;
        font-size: 64px;
        line-height: 72px;
      }

      p {
        margin-top:30px;
      }
    }
  }

  &.robot-variant {
    .left-pane {
      & > .content {
        .header-group {
          & > img {
            height:auto;
            max-width:160px;
            width:100%;
          }
        }
      }
    }

    .right-pane {
      // background: linear-gradient(0deg, rgba(0, 24, 113, 0.71), rgba(0, 24, 113, 0.71)), url('../img/aro-fixing-robot.jpg') center right / cover no-repeat;
      background: linear-gradient(0deg, rgba(73, 10, 85, 0.74) 0%, rgba(73, 10, 85, 0.74) 100%), url('../img/roboworx-robots.jpg') center center / cover no-repeat;

      & > .content {
        h1 {
          font-weight:600;
        }

        img {
          max-width: 240px;
          width:100%;
        }
      }
    }
  }
}